<template>
  <div>
    <b-row class="mb-2">
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <label>
            <strong>Date From</strong>
          </label>
          <b-input
            v-model="tableSalesOrders.filter.date_from"
            type="date"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <label>
            <strong>Date To</strong>
          </label>
          <b-input
            v-model="tableSalesOrders.filter.date_to"
            type="date"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="4"
      >
        <b-form-group>
          <label>
            <strong>Status</strong>
          </label>
          <b-select
            v-model="tableSalesOrders.filter.status"
            :options="filterStatuses"
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        :md="isUserAllowedForFilterDistributions ? 8 : 12"
      >
        <b-form-group>
          <label>
            <strong>Search</strong>
          </label>
          <b-input
            v-model="tableSalesOrders.filter.search"
            type="text"
            placeholder="search here"
            debounce="1000"
          />
        </b-form-group>
      </b-col>

      <b-col
        v-if="isUserAllowedForFilterDistributions"
        cols="12"
        md="4"
      >
        <b-form-group>
          <label>
            <strong>Distribution</strong>
          </label>
          <b-select
            v-model="tableSalesOrders.filter.distribution"
            :options="filterDistributions"
          />
        </b-form-group>
      </b-col>

      <b-col
        v-if="isUserAllowedForExport"
        class="mb-2"
      >
        <b-overlay
          rounded
          opacity="0.1"
          spinner-small
          class="d-inline"
          spinner-variant="warning"
          :show="exporter.excel.exporting"
        >
          <json-excel
            :class="[
              'btn btn-success', {
                'btn-outline-success disabled': exporter.excel.exporting || !isValidForExport
              }
            ]"
            :fetch="excelProvider"
            :before-generate="onStartExcelExport"
            :before-finish="onFinishExcelExport"
            :name="excelFileName"
            :fields="exporter.excel.fields"
            :escape-csv="exporter.excel.escape"
            :type="exporter.excel.type"
            :header="exporter.excel.header"
            :footer="exporter.excel.footer"
            worksheet="Sales Orders"
          >
            {{ exporter.excel.exporting ? 'Exporting' : 'Export As Excel' }}
          </json-excel>
        </b-overlay>
      </b-col>
    </b-row>

    <b-table
      ref="tableSalesOrders"
      hover
      responsive
      :items="tableProvider"
      :filter="tableSalesOrders.filter"
      :fields="tableSalesOrders.fields"
      :sort-by.sync="tableSalesOrders.sortBy"
      :sort-desc.sync="tableSalesOrders.sortDesc"
      :sort-direction="tableSalesOrders.sortDirection"
      :filter-included-fields="tableSalesOrders.filterOn"
      :current-page="tableSalesOrders.currentPage"
      :per-page="tableSalesOrders.perPage"
      show-empty
    >
      <template #cell(index)="data">
        {{ tableSalesOrders.currentPage * tableSalesOrders.perPage - tableSalesOrders.perPage + (data.index + 1) }}
      </template>

      <template #cell()="data">
        <div class="text-nowrap">
          {{ data.value }}
        </div>
      </template>

      <template #cell(status)="data">
        <div class="text-nowrap">
          {{ getStatus(data.item ) }}
        </div>
      </template>

      <template #cell(warehouse_pick_up)="data">
        <span :class="data.item.warehouse_pick_up == 1 ? 'text-success' :'text-danger' "> {{ data.item.warehouse_pick_up === 1 ? 'Yes' : 'No' }}</span>
      </template>

      <template #cell(is_close)="data">
        <span :class="data.item.is_close === 1 ? 'text-success' : 'text-danger'"> {{ data.item.is_close === 1 ? 'Yes' : 'No' }}</span>
      </template>

      <template #cell(actions)="row">
        <div class="text-nowrap">
          <b-button
            size="sm"
            class="mr-1"
            @click="onView(row.item)"
          >
            Show Details
          </b-button>
          <b-button
            v-if="row.item.status == 'Rejected' && (user ? user.role : '') === 'user'"
            size="sm"
            variant="success"
            @click="onEdit(row.item)"
          >
            Edit Sales Order
          </b-button>
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col
        cols="12"
        sm="6"
      >
        <div class="w-100 w-sm-25 mb-2 sm-mb-2">
          <b-select
            v-model="tableSalesOrders.perPage"
            :options="tableSalesOrders.pageOptions"
            size="sm"
          />
        </div>
      </b-col>
      <b-col
        cols="12"
        sm="6"
        class="d-flex justify-content-center justify-content-sm-end"
      >
        <b-pagination
          v-model="tableSalesOrders.currentPage"
          :total-rows="tableSalesOrders.totalRows"
          :per-page="tableSalesOrders.perPage"
          first-number
          last-number
          pills
          prev-text="Prev"
          next-text="Next"
          aria-controls="table"
        />
      </b-col>
    </b-row>

    <b-modal
      id="modal-show-details"
      scrollable
      no-close-on-backdrop
      title="Show Details"
      size="xl"
      @ok="onValidateSOConfirmation"
    >
      <b-tabs
        no-body
      >
        <b-tab
          title="Order Details"
          active
        >
          <b-row>
            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group>
                <label for="created_at">Transaction Date</label>
                <b-input
                  id="created_at"
                  v-model="salesOrder.created_at"
                  name="created_at"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group>
                <label for="document_number">Document Number</label>
                <b-input
                  id="document_number"
                  v-model="salesOrder.document_number"
                  name="document_number"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="4"
            >
              <b-form-group>
                <label for="customer_code">Customer Code</label>
                <b-input
                  id="customer_code"
                  v-model="salesOrder.customer_code"
                  name="customer_code"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label for="customer_name">Customer Name</label>
                <b-input
                  id="customer_name"
                  v-model="salesOrder.customer_name"
                  name="customer_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="3"
            >
              <b-form-group>
                <label for="term_name">Payment Term</label>
                <b-input
                  id="term_name"
                  v-model="salesOrder.term_name"
                  name="term_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="3"
            >
              <b-form-group>
                <label for="discount_name">Discount Type</label>
                <b-input
                  id="discount_name"
                  v-model="salesOrder.discount_name"
                  name="discount_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="3"
            >
              <b-form-group>
                <label for="discount_percentage">Discount %</label>
                <b-input
                  id="discount_percentage"
                  v-model="salesOrder.discount_percentage"
                  name="discount_percentage"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              sm="3"
            >
              <b-form-group>
                <label for="shipment_type">Shipment Type</label>
                <b-input
                  id="shipment_type"
                  :value="salesOrder.warehouse_pick_up === 1 ? 'For Pick Up' : 'For Delivery'"
                  name="shipment_type"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <label for="remarks">Remarks</label>
                <b-textarea
                  id="remarks"
                  v-model="salesOrder.remarks"
                  name="remarks"
                  type="text"
                  :disabled="true"
                  rows="6"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-row>
                <b-col
                  cols="12"
                  class="mb-2"
                >
                  <strong>Ordered Item(s)</strong>
                </b-col>
                <b-col cols="12">
                  <b-table
                    striped
                    hover
                    responsive
                    :items="salesOrderItems"
                    :fields="tableSalesOrderItems.fields"
                  />
                </b-col>
                <b-col
                  cols="6"
                  class="d-flex justify-content-end align-items-end"
                >
                  <b>Total Quantity:&nbsp;<span>{{ salesOrder.total_quantity }}</span></b>
                </b-col>
                <b-col
                  cols="6"
                  class="d-flex justify-content-end align-items-end"
                >
                  <b>Total Cost:&nbsp;<span>{{ numberFormatter(salesOrder.total_cost) }}</span></b>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

        </b-tab>

        <b-tab
          v-if="tableProductHistories.data.length > 0"
          title="Transaction History"
        >
          <b-card
            v-for="(product, index) in tableProductHistories.data"
            :key="index"
            no-body
          >
            <p
              v-b-toggle="`collapse-${index}-inner`"
              class="pl-2 py-2 m-0"
              :style="`cursor:pointer;  ${product.status === 'Delivered' || product.status === 'Picked-up' ? 'background-color:#06C270;color:white' : 'background-color:#dee2e6'}`"
            >
              Transaction Date: {{ dateFormatter(product.delivered_at) }}
            </p>
            <b-collapse :id="`collapse-${index}-inner`">
              <p class="mt-2">
                Prepared By: {{ product.approved_by }}<br>
                Status: {{ product.status }}
              </p>
              <b-table
                striped
                hover
                :items="product.transaction_items"
                :fields="tableProductHistories.fields"
              >
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(product_name)="data">
                  {{ data.item.sale_order_detail.product_name }}
                </template>
              </b-table>
            </b-collapse>
          </b-card>
        </b-tab>

        <b-tab
          title="Group Approver"
        >
          <b-table
            striped
            hover
            responsive
            :items="salesOrderApprovers"
            :fields="tableApprovers.fields"
          >
            <template #cell(index)="data">
              {{ data.index + 1 }}
            </template>
          </b-table>
        </b-tab>

      </b-tabs>

      <template #modal-footer="{cancel, ok}">
        <b-button
          v-if="isCloseButtonVisible"
          variant="success"
          @click="ok()"
        >
          Close Sales Order
        </b-button>
        <b-button
          variant="danger"
          :disabled="isBusy"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

    <SalesOrderFormComponents
      ref="soForm"
      :datax="formSalesOrder"
      @onFilterChange="tableRefresh"
    />

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SalesOrderFormComponents from '@/components/salesorder/SalesOrderFormComponents.vue'
import { SharedListService, SharedSalesOrderService } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'TrackerTable',

  components: {
    SalesOrderFormComponents
  },

  mixins: [formatter],

  data () {
    return {
      isBusy: false,
      distributions: [],
      salesOrderItems: [],
      salesOrderApprovers: [],
      salesOrder: {
        id: 0,
        created_at: '',
        document_number: '',
        customer_code: '',
        customer_name: '',
        term_name: '',
        discount_name: '',
        discount_percentage: '',
        remarks: '',
        total_cost: 0,
        total_quantity: 0,
        is_close: 0
      },
      formSalesOrder: {
        id: '',
        active: 0,
        distribution_id: null,
        customer_id: null,
        customer_code: '',
        document_number: '',
        term_id: null,
        discount_id: null,
        discount_percentage: 0,
        remarks: ''
      },
      exporter: {
        currentRow: 0,
        excel: {
          exporting: false,
          header: [],
          escape: false,
          type: 'xls',
          fields: {
            'Sales Orders': {
              field: '',
              callback: salesOrders => this.generateTableRows(salesOrders)
            }
          },
          footer: []
        }
      },
      tableSalesOrders: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          distribution: 'All',
          status: this.$route.params?.status || 'All',
          date_from: '',
          date_to: ''
        },
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', label: 'transaction date', sortable: true, formatter: this.dateTimeFormatter },
          { key: 'document_number.', sortable: true },
          { key: 'customer_name', sortable: true },
          { key: 'status', sortable: true },
          { key: 'group_approver', sortable: true },
          { key: 'warehouse_pick_up', sortable: true },
          { key: 'is_close', sortable: true }
          // { key: 'distribution_code', sortable: true }
        ]
      },
      tableSalesOrderItems: {
        fields: [
          { key: 'category_name' },
          { key: 'product_name' },
          { key: 'quantity' },
          { key: 'delivered_quantity' },
          { key: 'pending_deliver' },
          { key: 'price', formatter: this.numberFormatter, tdClass: 'text-right' },
          { key: 'total_price', formatter: this.numberFormatter, tdClass: 'text-right' }
        ]
      },
      tableApprovers: {
        fields: [
          'index',
          { key: 'approver_name' },
          { key: 'role' },
          { key: 'status' },
          { key: 'date_approved' },
          { key: 'comment' }
        ]
      },
      tableProductHistories: {
        fields: [
          'index',
          { key: 'product_name' },
          { key: 'quantity' }
        ],
        data: []
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),

    filterDistributions () {
      return [{ value: 'All', text: 'All' }].concat(this.distributions)
    },

    filterStatuses () {
      if (['sra', 'cco', 'user', 'manager', 'tm'].includes(this.user?.role)) {
        return [
          { text: 'All', value: 'All' },
          { text: 'Pending', value: 'Pending' },
          { text: 'Approved', value: 'Approvesracco' },
          { text: 'Partially Served', value: 'Partially Approved' },
          { text: 'Fully Served', value: 'Approved' },
          { text: 'Partially Delivered', value: 'Partially Delivered' },
          { text: 'Fully Delivered', value: 'Delivered' },
          { text: 'Closed Sales Order', value: 'close_so' },
          { text: 'Rejected', value: 'Rejected' }
        ]
      } else if (this.user?.role === 'warehouse') {
        return [
          { text: 'All', value: 'All' },
          { text: 'Pending', value: 'Pending' },
          { text: 'Rejected', value: 'Rejected' },
          { text: 'Partially Served', value: 'Partially Approved' },
          { text: 'Fully Serve', value: 'Approved' },
          { text: 'Partially Delivered', value: 'Partially Delivered' },
          { text: 'Fully Delivered', value: 'Delivered' },
          { text: 'Closed Sales Order', value: 'close_so' }
        ]
      }

      return [
        { text: 'All', value: 'All' },
        { text: 'Pending', value: 'Pending' },
        { text: 'Rejected', value: 'Rejected' },
        { text: 'Partially Delivered', value: 'Partially Delivered' },
        { text: 'Fully Delivered', value: 'Delivered' }
      ]
    },

    isCloseButtonVisible () {
      return !!(this.user?.role === 'sra' || this.user?.role === 'cco') && this.salesOrder.is_close === 0
    },

    isUserAllowedForFilterDistributions () {
      return this.user?.role !== 'user'
    },

    isUserAllowedForExport () {
      return !['sra', 'warehouse', 'logistics'].includes(this.user?.role)
    },

    excelFileName () {
      const dateFrom = this.dateShortFormatter(this.tableSalesOrders.filter.date_from)
      const dateTo = this.dateShortFormatter(this.tableSalesOrders.filter.date_to)

      return `Sales Orders - ${dateFrom} - ${dateTo}`
    },

    isValidForExport () {
      // sra, warehouse, logistics
      const isValidDateFrom = ![null, '', ' '].includes(this.tableSalesOrders.filter.date_from)
      const isValidDateTo = ![null, '', ' '].includes(this.tableSalesOrders.filter.date_to)

      return isValidDateFrom && isValidDateTo && Number(this.tableSalesOrders.totalRows) >= 1 && this.tableSalesOrders.filter.status !== 'All'
    }
  },

  mounted () {
    this.getFilterDistributions()
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableSalesOrders.busy = true

      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search,
        distribution: filter.distribution,
        status: filter.status,
        date_from: filter.date_from,
        date_to: filter.date_to,
        tracker: 1
      })

      return await SharedSalesOrderService.getPendingSalesOrder(filters).then(
        ({ data }) => {
          this.tableSalesOrders.totalRows = data.total_rows
          return data.data
        }
      )
        .catch(() => {
          this.tableSalesOrders.totalRows = 0
          return []
        })
        .finally(() => {
          this.tableSalesOrders.busy = false
        })
    },

    tableRefresh () {
      this.$refs.tableSalesOrders.refresh()
    },

    generateTableRows (salesOrder) {
      this.currentRow += 1

      const tableHeaders = (
        `
          <thead>
            <tr>
              <th>Transaction Date</th>
              <th>Document Number</th>
              <th>Customer Number</th>
              <th>Status</th>
              <th>Category Code</th>
              <th>Category Name</th>
              <th>Product Code</th>
              <th>Product Name</th>
              <th>Ordered Quantity</th>
              <th>Product Price</th>
              <th>Ordered Total Cost</th>
              <th>Served Quantity</th>
              <th>Served Total Cost</th>
            </tr>
          </thead>
        `
      ).replace(/\n/g, '')

      const tableRows = Array.from(salesOrder.sale_order_details).map(
        order => {
          const orderedTotalCost = Number(order.price) * Number(order.quantity)
          const servedTotalCost = Number(order.price) * Number(order.quantity_out)

          const noQuantityVariance = Number(order.quantity) === Number(order.quantity_out)

          return (
              `
                <tr>
                  <td>${this.dateTimeFormatter(salesOrder.created_at)}</td>
                  <td>${salesOrder.document_number}</td>
                  <td>${salesOrder.customer_name}</td>
                  <td>${salesOrder.status}</td>
                  <td>${order.category_code}</td>
                  <td>${order.category_name}</td>
                  <td>${order.product_code}</td>
                  <td>${order.product_name}</td>
                  <td>${order.quantity}</td>
                  <td>${order.price}</td>
                  <td>${orderedTotalCost}</td>
                  <td style="color: ${noQuantityVariance ? 'black' : 'red'};">${order.quantity_out}</td>
                  <td>${servedTotalCost}</td>
                </tr>
              `
          ).replace(/\n/g, '')
        }
      ).join('')

      if (this.currentRow === 1) {
        return (
          `
            <table>
              ${tableHeaders}
              <tbody>
                ${tableRows}
              </tbody>
            </table>
          `
        ).replace(/\n/g, '')
      }

      return (
        `
          <table>
            <tbody>
              ${tableRows}
            </tbody>
          </table>
        `
      ).replace(/\n/g, '')
    },

    async excelProvider () {
      if (this.exporter.busy || [this.tableSalesOrders.filter.date_from, this.tableSalesOrders.filter.date_to].includes(null)) {
        return null
      }

      this.exporter.busy = this.exporter.excel.exporting = true

      this.currentRow = 0

      const filters = this.objectToUrl({
        filter_text: this.tableSalesOrders.filter.search,
        distribution: this.tableSalesOrders.filter.distribution,
        status: this.tableSalesOrders.filter.status,
        date_from: this.tableSalesOrders.filter.date_from,
        date_to: this.tableSalesOrders.filter.date_to,
        tracker: 1
      })

      return await SharedSalesOrderService.exports(filters).then(
        ({ data: { items } }) => {
          if (items.length <= 0) {
            this.swalInvalid(
              'No available data to be Exported'
            )
          }

          return items
        }
      ).catch(() => {
        return []
      }).finally(() => {
        this.exporter.busy = this.exporter.excel.exporting = false
      })
    },

    onStartExcelExport () {
      if (this.exporter.busy || [this.tableSalesOrders.filter.date_from, this.tableSalesOrders.filter.date_to].includes(null)) {
        return null
      }

      this.exporter.excel.header = []

      this.exporter.excel.footer = []
    },

    onFinishExcelExport () {
    },

    async getFilterDistributions () {
      this.distributions = []

      if (this.isUserAllowedForFilterDistributions) {
        await SharedListService.getDistributionList().then(
          ({ data }) => {
            this.distributions = data.map(
              distribution => ({
                value: distribution.id,
                text: distribution.distribution_name
              })
            )
          }
        )
      }
    },

    getStatus (row) {
      let newStatus = row.status

      if (row.group_approver === 'Warehouse') {
        if (row.status === 'Approved') {
          newStatus = 'Fully Serve'
        } else if (row.status === 'Partially Approved') {
          newStatus = 'Partially Serve'
        } else {
          newStatus = row.status
        }
      } else if (row.group_approver === 'Logistics') {
        if (row.status === 'Pending') {
          newStatus = 'For Delivery'
        } else if (row.status === 'Delivered') {
          newStatus = 'Fully Delivered'
        } else {
          newStatus = row.status
        }
      }

      return newStatus
    },

    getQuantity (id, item, status) {
      return item?.transactions.filter(el => {
        if (status.indexOf(el.status) !== -1) {
          return el
        }
      }).flatMap(el => {
        return el?.transaction_items.map((t, ti) => {
          if (id === t.sale_order_detail_id) {
            return t
          } else {
            return { quantity: 0 }
          }
        })
      })?.reduce((prev, current) => {
        // eslint-disable-next-line no-return-assign
        return prev += current.quantity
      }, 0)
    },

    onEdit (item) {
      if (item.status === 'Rejected') {
        this.formSalesOrder = item
        this.$refs.soForm.showModal()
      } else {
        alert('Hey! Only the rejected sales order should be edited.')
      }
    },

    onView (item) {
      this.salesOrder.id = item.id
      this.salesOrder.created_at = this.dateTimeFormatter(item.created_at)
      this.salesOrder.document_number = item.document_number
      this.salesOrder.customer_code = item.customer_code
      this.salesOrder.customer_name = item.customer_name
      this.salesOrder.term_name = item.term_name
      this.salesOrder.discount_name = item.discount_name
      this.salesOrder.discount_percentage = item.discount_percentage
      this.salesOrder.remarks = item.remarks
      this.salesOrder.is_close = item.is_close

      const mappedItems = item.sale_order_details.map(x => {
        return {
          id: x.id,
          category_name: x.category_name,
          product_name: x.product_name,
          quantity: x.quantity,
          price: x.price,
          total_price: Number(x.quantity, 2) * Number(x.price, 2),
          quantity_out: 0,
          delivered_quantity: this.getQuantity(x.id, item, ['Delivered', 'Picked-up']),
          disabled: x.quantity_out === x.quantity,
          pending_deliver: this.getQuantity(x.id, item, ['Pending'])
        }
      })

      const sumTotalPrice = mappedItems.reduce((totalPrice, item) => {
        return totalPrice + parseFloat(item.total_price)
      }, 0)

      this.salesOrder.total_cost = sumTotalPrice

      const sumTotaQuantity = mappedItems.reduce((totalQuantity, item) => {
        return totalQuantity + parseFloat(item.quantity)
      }, 0)

      this.salesOrder.total_quantity = sumTotaQuantity

      const approvers = [
        {
          approver_name: item.stage_one_name,
          role: 'Sales Receivable Accountant',
          status: item.stage_one_status,
          date_approved: item.stage_one_at,
          comment: item.stage_one_comment
        },
        {
          approver_name: item.stage_two_name,
          role: 'Credit Collection Officer',
          status: item.stage_two_status,
          date_approved: item.stage_two_at,
          comment: item.stage_two_comment
        },
        {
          approver_name: item.stage_three_name,
          role: 'Warehouse',
          status: item.stage_three_status,
          date_approved: item.stage_three_at,
          comment: item.stage_three_comment
        },
        {
          approver_name: item.stage_four_name,
          role: 'Logistics',
          status: item.stage_four_status,
          date_approved: item.stage_four_at,
          comment: item.stage_four_comment
        }
      ]

      this.tableProductHistories.data = item?.transactions || []
      this.salesOrderItems = mappedItems
      this.salesOrderApprovers = approvers

      this.$bvModal.show('modal-show-details')
    },

    onValidateSOConfirmation (bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$swal.fire({
        icon: 'question',
        title: 'Close this Sales Order?',
        showCancelButton: true,
        confirmButtonColor: '#06C270',
        confirmButtonText: 'Confirm',
        cancelButtonColor: '#FF2929',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          return this.onCloseSalesOrder()
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      })
    },

    async onCloseSalesOrder () {
      return new Promise(resolve => {
        const { id } = this.salesOrder
        SharedSalesOrderService.updateCloseSo({ id }).then(
          ({ data }) => {
            this.$bvModal.hide('modal-show-details')
            this.$swal.fire({
              icon: 'success',
              title: 'Successful',
              text: data.message,
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Dismiss'
            }).then(() => {
              this.tableRefresh()
            })
          }
        ).finally(resolve)
      })
    }
  }
}
</script>

<style scoped>
  .hide{
    display:none;
  }
</style>
